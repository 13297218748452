import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const ShieldIcon = () => (
  <StaticQuery
    query={graphql`
      query {
        shieldIcon: file(relativePath: { eq: "icons/ShieldIcon.png" }) {
          childImageSharp {
            gatsbyImageData(height: 75)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.shieldIcon.childImageSharp.gatsbyImageData} alt="Sheild Icon" />}
  />
);

export default ShieldIcon;
