import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const HandShakeIcon = () => (
  <StaticQuery
    query={graphql`
      query {
        handShakeIcon: file(relativePath: { eq: "icons/HandshakeIcon.png" }) {
          childImageSharp {
            gatsbyImageData(height: 63)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.handShakeIcon.childImageSharp.gatsbyImageData} alt="Handshake Icon" />}
  />
);

export default HandShakeIcon;
