import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import styleConfig from '../../../style-config';
// import classNames from 'classnames';

const styles = theme => ({
  faqWrapper: {
    ...theme.mixins.layout.column.base,
    ...theme.mixins.noSelect,
    width: '100%',
    maxWidth: '800px',
    margin: 'auto',
  },
  titleWrapper: {
    color: theme.mixins.color.titleTextBlue,
    ...theme.mixins.noSelect,
    margin: '50px 0 25px 24px',
    [`@media screen and (max-width: ${styleConfig.media.mobileSmall}px)`]: {
      marginLeft: '0px',
      textAlign: 'center',
    },
  },
  body: {
    color: theme.mixins.color.grayText,
    ...theme.mixins.noSelect,
  },
});

const FAQDrawer = ({ classes, title, children }) => (
  <div className={classes.faqWrapper}>
    {
      title && (
        <div className={classes.titleWrapper}>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
        </div>
      )
    }
    <div className={classes.body}>
      {children}
    </div>
  </div>
);

FAQDrawer.propTypes = {
  classes: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

FAQDrawer.defaultProps = {
  classes: {},
  title: null,
};

export default withStyles(styles)(FAQDrawer);
