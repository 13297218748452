import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import {
  TrustPilotSticker,
} from '@cleverrealestate/clever-components';
import Review from '../global/Review';
import JCYFace from '../../images/img_comps/people/agentFaces/JCYFace';
import SarahFace from '../../images/img_comps/people/agentFaces/SarahFace';
import JayFace from '../../images/img_comps/people/agentFaces/JayFace';
import BrandonFace from '../../images/img_comps/people/agentFaces/BrandonFace';
import TrustPilotBlack from '../../images/img_comps/trustPilot/TrustPilotBlack';
import TrustPilotWhite from '../../images/img_comps/trustPilot/TrustPilotWhite';

const styles = theme => ({
  reviewsWrapper: {
    ...theme.mixins.layout.column.base,
    ...theme.mixins.noSelect,
    width: '100%',
    alignItems: 'center',
  },
  titleWrapper: {
    ...theme.mixins.topBarAlign,
    ...theme.mixins.layout.column.base,
    alignItems: 'center',
    color: theme.mixins.color.titleTextBlue,
    marginBottom: '25px',
    textAlign: 'center',
  },
  title: {
    maxWidth: '100%',
  },
  body: {
    color: theme.mixins.color.grayText,
    textAlign: 'center',
    maxWidth: '100%',
  },
  reviews: {
    ...theme.mixins.layout.row.base,
    width: '100%',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  trustPilotWrapper: {
    ...theme.mixins.layout.row.base,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const AgentReviews = ({ classes }) => (
  <div className={classes.reviewsWrapper}>
    <div className={classes.titleWrapper}>
      <Typography className={classes.title} variant="h6" color="inherit">
        Don’t Just Take Our Word For It
      </Typography>
      <div className={classes.body}>
        <Typography variant="body1" color="inherit">
          Read Reviews from Some of Clever&#39;s Partner Agents.
        </Typography>
      </div>
    </div>
    <div className={classes.reviews}>
      <Review
        text={'I recommend Clever Real Estate to Sellers, Buyers, and Real Estate Agents. '
              + 'This is how a referral service should operate! Clever offers solid leads, '
              + 'great communication, and a cost effective business model. Glad I am a partner!'}
        name="Sherril D."
        picture={<SarahFace />}
        callout="8 YEARS EXP."
      />
      <Review
        text={'Clever Real Estate is awesome! A great company that with a great model that is beneficial'
              + ' for both the home owner and the real estate agent.'
              + "It's a great way for home owners to save money and they have an effective business model."
              + 'Communication is great and so is their customer service!'}
        name="Brandon R."
        picture={<BrandonFace />}
        callout="5 STAR AGENT"
      />
      <Review
        text={'Clever communicates and stays in touch. They do what they promise. '
              + 'As a Clever partner agent in North Texas I am proud to represent this company. '
              + 'We take great care of our Clever clientele in selling, '
              + 'what is for most people, their largest financial asset....'}
        name="JC Y."
        picture={<JCYFace />}
        callout="125 SALES"
      />
      <Review
        text={'Clever Real Estate is an excellent company to partner with. '
              + 'Very hands on with the seller and the seller’s agent for a smooth transaction to closing. '
              + 'This is an excellent opportunity for anyone considering selling '
              + 'their home to start their next chapter in their lives.'}
        name="Kenneth A."
        picture={<JayFace />}
        callout="20 YEARS EXP."
      />
    </div>
    <div className={classes.trustPilotWrapper}>
      <TrustPilotSticker
        textColor="black"
        trustPilotBlack={<TrustPilotBlack />}
        trustPilotWhite={<TrustPilotWhite />}
      />
    </div>
  </div>
);


AgentReviews.propTypes = {
  classes: PropTypes.any,
};

AgentReviews.defaultProps = {
  classes: {},
};

export default withStyles(styles)(AgentReviews);
