import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import GreenCheckMark from '../../images/img_comps/icons/GreenCheckMark';
import styleConfig from '../../style-config';

const styles = theme => ({
  agentChecksWrapper: {
    ...theme.mixins.layout.column.base,
    marginTop: '30px',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      marginTop: '0',
    },
  },
  checksRow: {
    ...theme.mixins.layout.row.base,
    color: 'white',
    alignItems: 'center',
    marginTop: '10px',
  },
  body: {
    ...theme.mixins.noSelect,
  },
  check: {
    marginRight: '15px',
  },
});

const AgentCheckMarks = ({ classes }) => (
  <div className={classes.agentChecksWrapper}>
    <div className={classes.checksRow}>
      <div className={classes.check}>
        <GreenCheckMark />
      </div>
      <Typography className={classes.body} variant="body2" color="inherit" gutterBottom>
        Qualified clients ready to buy or sell.
      </Typography>
    </div>
    <div className={classes.checksRow}>
      <div className={classes.check}>
        <GreenCheckMark />
      </div>
      <Typography className={classes.body} variant="body2" color="inherit" gutterBottom>
        Grow your brand with more listings.
      </Typography>
    </div>
    <div className={classes.checksRow}>
      <div className={classes.check}>
        <GreenCheckMark />
      </div>
      <Typography className={classes.body} variant="body2" color="inherit" gutterBottom>
        Free to join - Never pay for a lead up front.
      </Typography>
    </div>
  </div>
);

AgentCheckMarks.propTypes = {
  classes: PropTypes.any,
};

AgentCheckMarks.defaultProps = {
  classes: {},
};

export default withStyles(styles)(AgentCheckMarks);
