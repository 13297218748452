import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ForbesLogoFluid from '../../images/img_comps/publications/ForbesLogoFluid';
import YahooLogoFluid from '../../images/img_comps/publications/YahooLogoFluid';
import InmanLogoFluid from '../../images/img_comps/publications/InmanLogoFluid';
import NasdaqLogoFluid from '../../images/img_comps/publications/NasdaqLogoFluid';
import FoxLogoFluid from '../../images/img_comps/publications/FoxLogoFluid';
import CNBCLogoFluid from '../../images/img_comps/publications/CNBCLogoFluid';
import RealtorLogoFluid from '../../images/img_comps/publications/RealtorLogoFluid';
import styleConfig from '../../style-config';


const styles = theme => ({
  title: {
    maxWidth: '100%',
    color: theme.mixins.color.titleTextBlue,
  },
  wideIconWrapper: {
    width: '120px',
    margin: '20px 25px',
    [`@media screen and (max-width: ${styleConfig.media.mobileSmall}px)`]: {
      width: '90px',
    },
  },
  foxIconWrapper: {
    width: '90px',
    margin: '20px 25px',
  },
  cnbcIconWrapper: {
    width: '65px',
    margin: '20px 25px',
    [`@media screen and (max-width: ${styleConfig.media.mobileSmall}px)`]: {
      margin: '20px 35px',
    },
  },
  realtorIconWrapper: {
    width: '150px',
    margin: '20px 25px',
  },
});

const AsSeenIn = ({ classes }) => (
  <Grid direction="column" justify="center" alignItems="center" container>
    <Typography className={classes.title} variant="h6" color="inherit" gutterBottom>
      As Seen In
    </Typography>
    <Grid direction="row" justify="center" alignItems="center" container>
      <div className={classes.wideIconWrapper}>
        <ForbesLogoFluid />
      </div>
      <div className={classes.wideIconWrapper}>
        <YahooLogoFluid />
      </div>
      <div className={classes.wideIconWrapper}>
        <InmanLogoFluid />
      </div>
      <div className={classes.wideIconWrapper}>
        <NasdaqLogoFluid />
      </div>
      <div className={classes.foxIconWrapper}>
        <FoxLogoFluid />
      </div>
      <div className={classes.cnbcIconWrapper}>
        <CNBCLogoFluid />
      </div>
      <div className={classes.realtorIconWrapper}>
        <RealtorLogoFluid />
      </div>
    </Grid>
  </Grid>
);

AsSeenIn.propTypes = {
  classes: PropTypes.any,
};

AsSeenIn.defaultProps = {
  classes: {},
};

export default withStyles(styles)(AsSeenIn);
