import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const NasdaqLogoFluid = () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "publications/NasdaqLogo.png" }) {
          childImageSharp {
            gatsbyImageData(width: 190)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.logo.childImageSharp.gatsbyImageData} alt="Nasdaq Logo" />}
  />
);

export default NasdaqLogoFluid;
