import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import validate from 'validate.js';
import {
  ExperienceLevelTypes,
  DrivingDistanceTypes,
  AgentFormInfo,
  submitHubSpotForm,
  styleConfig,
} from '@cleverrealestate/clever-helpers';
import {
  SubmitButton,
  ZipCodeInput,
  TextInput,
  PhoneNumberInput,
  EmailInput,
  NumberInput,
  RadioGroupInput,
} from '@cleverrealestate/clever-components';
import { useTextConsent } from '@cleverrealestate/clever-components-v2';

const styles = theme => ({
  agentFormWrapper: {
    ...theme.mixins.layout.column.base,
    width: '100%',
  },
  titleWrapper: {
    ...theme.mixins.layout.column.base,
    ...theme.mixins.noSelect,
    alignItems: 'center',
    color: theme.mixins.color.titleTextBlue,
    marginBottom: '25px',
    textAlign: 'center',
  },
  title: {
    maxWidth: '100%',
  },
  body: {
    color: theme.mixins.color.grayText,
    textAlign: 'center',
    maxWidth: '100%',
  },
  formWrapper: {
    ...theme.mixins.layout.column.base,
  },
  groupWrapper: {
    ...theme.mixins.layout.row.base,
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: '600px',
    width: '100%',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      ...theme.mixins.layout.column.base,
      alignItems: 'center',
    },
  },
  formGroup: {
    ...theme.mixins.layout.column.base,
    width: '280px',
  },
  textField: {
    width: '100%',
    height: '70px',
  },
  submitWrapper: {
    ...theme.mixins.layout.column.base,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32px',
    color: theme.mixins.color.titleTextBlue,
    textAlign: 'center',
  },
  disclaimer: {
    color: theme.mixins.color.grayText,
    maxWidth: '600px',
    fontSize: '13px',
    marginTop: '24px',
    marginBottom: '0px',
  },
  disclaimerLink: {
    color: theme.mixins.color.grayText,
  },
  submitButton: {
    borderRadius: '4px',
    width: '125px',
    height: '40px',
    color: 'white',
    ...theme.mixins.color.buttonGradient,
  },
  disabled: {
    color: 'white',
    backgroundColor: theme.mixins.color.grayText,
  },
  error: {
    color: 'red',
  },
});

const AgentForm = ({ classes }) => {
  const [values, setValues] = useState({
    fullName: { value: '', error: null }, // *
    phoneNumber: { value: '', error: null }, // *
    email: { value: '', error: null }, // *
    brokerage: { value: '', error: null }, // *
    salesPerYear: { value: '', error: null },
    zipCode: { value: '', error: null }, // *
    profileLink: { value: '', error: null }, // *
    websiteLink: { value: '', error: null },
    discoverySource: { value: '', error: null },
    discoverySourceOther: { value: '', error: null },
    yearsOfExp: ExperienceLevelTypes.lessThan5,
    milesCovered: DrivingDistanceTypes.miles1to10,
  });
  const {
    getHTML,
    getConsentString,
  } = useTextConsent('Submit');
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const discoverySourceOtherInput = useRef(null);

  const checkFields = [
    'fullName',
    'phoneNumber',
    'email',
    'brokerage',
    'salesPerYear',
    'zipCode',
    'profileLink',
    'websiteLink',
    'discoverySource',
    'discoverySourceOther',
  ];

  let bouncyError = null;

  const constraints = {
    fullName: {
      length: {
        maximum: 256,
      },
    },
    phoneNumber: {
      numericality: true,
      length: {
        minimum: 10,
        maximum: 11,
      },
    },
    email: {
      email: true,
    },
    brokerage: {
      length: {
        maximum: 256,
      },
    },
    salesPerYear: {
      numericality: true,
    },
    profileLink: {
      length: {
        maximum: 256,
      },
    },
    zipCode: {
      numericality: true,
      length: {
        is: 5,
      },
    },
    websiteLink: {
      length: {
        maximum: 256,
      },
    },
    discoverySourceOther: {
      length: {
        maximum: 256,
      },
    },
  };

  const handleFieldChange = name => (event) => {
    setValues({ ...values, [name]: { value: event.target.value, error: null } });
  };

  const disableSubmit = () => {
    const disable = values.fullName.value === ''
      || values.fullName.error !== null
      || values.phoneNumber.value === ''
      || values.phoneNumber.error !== null
      || values.email.value === ''
      || values.email.error !== null
      || values.brokerage.value === ''
      || values.brokerage.error !== null
      || values.zipCode.value === ''
      || values.zipCode.error !== null
      || values.profileLink.value === ''
      || values.profileLink.error !== null
      || values.websiteLink.error !== null
      || values.salesPerYear.error !== null
      || values.discoverySource.error !== null
      || values.discoverySourceOther.error !== null;
    return disable;
  };

  const checkForErrors = () => {
    const newValues = { ...values };
    checkFields.forEach((key) => {
      if (values[key].value !== '') {
        const errors = validate({ [key]: values[key].value }, constraints);
        if (errors !== undefined) {
          newValues[key] = {
            ...newValues[key],
            error: errors[key][0],
          };
        } else {
          newValues[key] = {
            ...newValues[key],
            error: null,
          };
        }
      }
    });
    setValues(newValues);
  };

  useEffect(() => {
    bouncyError = setTimeout(checkForErrors, 750);

    return () => {
      clearTimeout(bouncyError);
    };
  }, [
    values.fullName.value,
    values.phoneNumber.value,
    values.email.value,
    values.brokerage.value,
    values.salesPerYear.value,
    values.zipCode.value,
    values.profileLink.value,
    values.websiteLink.value,
  ]);

  useEffect(() => {
    if (values.discoverySource.value === 'Other' && discoverySourceOtherInput.current) {
      discoverySourceOtherInput.current.focus();
    }
  }, [values.discoverySource.value, discoverySourceOtherInput]);

  const submit = () => {
    let discoverySource = values.discoverySource.value;
    if (values.discoverySource.value === 'Other' && values.discoverySourceOther.value !== '') {
      discoverySource = `Other - ${values.discoverySourceOther.value}`;
    }

    setSubmitting(true);
    const formData = new AgentFormInfo(
      values.fullName.value,
      values.phoneNumber.value,
      values.email.value,
      values.salesPerYear.value,
      values.zipCode.value,
      values.profileLink.value,
      values.brokerage.value,
      values.websiteLink.value,
      values.yearsOfExp,
      values.milesCovered,
      discoverySource,
      getConsentString(),
    );
    const json = formData.createHubSpotJSON();
    const jsonString = JSON.stringify(json);
    const formId = process.env.AGENT_FORM_ID;
    submitHubSpotForm(jsonString, formId)
      .then((response) => {
        setSubmitting(false);
        if (response.status === 200) {
          setSuccess(true);
        }
      }, (errorResponce) => {
        setSubmitting(false);
        setError(errorResponce);
      });
  };

  const handleKeyDown = (event) => {
    if (!disableSubmit() && event.code === 'Enter') {
      submit();
    }
  };

  useEffect(() => {
    window.document.addEventListener('keyup', handleKeyDown);
    return () => {
      window.document.removeEventListener('keyup', handleKeyDown);
    };
  });

  return (
    <div className={classes.agentFormWrapper}>
      <div className={classes.titleWrapper}>
        <Typography className={classes.title} variant="h6" color="inherit">
          See If You Qualify to be a Clever Partner Agent
        </Typography>
        <Typography className={classes.body} variant="body1" color="inherit">
          Enter your details below and a team member will reach out to you within 24 hours.
        </Typography>
      </div>
      <div className={classes.formWrapper}>
        <div className={classes.groupWrapper}>
          <div className={classes.formGroup}>
            <TextInput
              required
              id="agent-full-name"
              label="Full Name"
              className={classes.textField}
              value={values.fullName.value}
              onChange={handleFieldChange('fullName')}
              margin="normal"
              autoComplete="section-agent name"
              error={values.fullName.error !== null}
              helperText={values.fullName.error}
              disabled={submitting}
            />
            <PhoneNumberInput
              required
              id="agent-phone-number"
              label="Phone Number"
              className={classes.textField}
              value={values.phoneNumber.value}
              onChange={handleFieldChange('phoneNumber')}
              margin="normal"
              section="section-agent"
              error={values.phoneNumber.error !== null}
              helperText={values.phoneNumber.error}
              disabled={submitting}
            />
            <EmailInput
              required
              id="agent-email"
              label="Email Address"
              className={classes.textField}
              value={values.email.value}
              onChange={handleFieldChange('email')}
              margin="normal"
              section="section-agent"
              error={values.email.error !== null}
              helperText={values.email.error}
              disabled={submitting}
            />
            <TextInput
              required
              id="agent-brokerage"
              label="Brokerage"
              className={classes.textField}
              value={values.brokerage.value}
              onChange={handleFieldChange('brokerage')}
              margin="normal"
              variant="outlined"
              autoComplete="section-agent"
              error={values.brokerage.error !== null}
              helperText={values.brokerage.error}
              disabled={submitting}
            />
          </div>
          <div className={classes.formGroup}>
            <NumberInput
              id="agent-sales-count"
              label="Sales Per Year"
              className={classes.textField}
              value={values.salesPerYear.value}
              onChange={handleFieldChange('salesPerYear')}
              margin="normal"
              autoComplete="section-agent"
              error={values.salesPerYear.error !== null}
              helperText={
                values.salesPerYear.error
                  ? values.salesPerYear.error
                  : 'Enter number of sales in the past 12 months.'
              }
              disabled={submitting}
            />
            <ZipCodeInput
              required
              id="agent-zip-code"
              label="Zip Code"
              className={classes.textField}
              value={values.zipCode.value}
              onChange={handleFieldChange('zipCode')}
              margin="normal"
              section="section-agent"
              error={values.zipCode.error !== null}
              helperText={
                values.zipCode.error
                  ? values.zipCode.error
                  : 'Primary Zip Code of Operation'
              }
              disabled={submitting}
            />
            <TextInput
              required
              id="agent-profile-link"
              label="Profile Link"
              className={classes.textField}
              value={values.profileLink.value}
              onChange={handleFieldChange('profileLink')}
              margin="normal"
              autoComplete="section-agent url"
              error={values.profileLink.error !== null}
              helperText={
                values.profileLink.error
                  ? values.profileLink.error
                  : 'Please enter a profile with reviews'
              }
              disabled={submitting}
            />
            <TextInput
              id="agent-website-link"
              label="Website Link"
              className={classes.textField}
              value={values.websiteLink.value}
              onChange={handleFieldChange('websiteLink')}
              margin="normal"
              autoComplete="section-agent url"
              error={values.websiteLink.error !== null}
              helperText={values.websiteLink.error}
              disabled={submitting}
            />
          </div>
        </div>
        <div className={classes.groupWrapper}>
          <div className={classes.formGroup}>
            <TextField
              required
              id="agent-discovery-source"
              label="How did you hear about us?"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              section="section-agent"
              error={values.discoverySource.error !== null}
              helperText={values.discoverySource.error}
              disabled={submitting}
              select
              SelectProps={{
                onChange: handleFieldChange('discoverySource'),
                value: values.discoverySource.value,
              }}
            >
              {['Online Search', 'Fellow Agent', 'Prospective Client', 'Other'].map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.formGroup}>
            {values.discoverySource.value.includes('Other') && (
              <TextInput
                inputProps={{ ref: discoverySourceOtherInput }}
                id="agent-discovery-source-other"
                label="Please share"
                className={classes.textField}
                value={values.discoverySourceOther.value}
                onChange={handleFieldChange('discoverySourceOther')}
                margin="normal"
                section="section-agent"
                error={values.discoverySourceOther.error !== null}
                helperText={values.discoverySourceOther.error}
                disabled={submitting}
              />
            )}
          </div>
        </div>
        <div className={classes.groupWrapper}>
          <div className={classes.formGroup}>
            <RadioGroupInput
              title="Years of experience"
              onChange={(value) => { setValues({ ...values, yearsOfExp: value }); }}
              options={[
                { value: ExperienceLevelTypes.lessThan5, label: 'Less than 5 Years', disabled: submitting },
                { value: ExperienceLevelTypes.years5to10, label: '5 - 10 Years', disabled: submitting },
                { value: ExperienceLevelTypes.years10to20, label: '10 - 20 Years', disabled: submitting },
                { value: ExperienceLevelTypes.moreThan20, label: 'More than 20 Years', disabled: submitting },
              ]}
            />
          </div>
          <div className={classes.formGroup}>
            <RadioGroupInput
              title="How many miles do you cover?"
              onChange={(value) => { setValues({ ...values, milesCovered: value }); }}
              options={[
                { value: DrivingDistanceTypes.miles1to10, label: '1 - 10 Miles', disabled: submitting },
                { value: DrivingDistanceTypes.miles10to20, label: '10 - 20 Miles', disabled: submitting },
                { value: DrivingDistanceTypes.miles20to30, label: '20 - 30 Miles', disabled: submitting },
                { value: DrivingDistanceTypes.miles30to40, label: '30 - 40 Miles', disabled: submitting },
              ]}
            />
          </div>
        </div>
      </div>
      <div className={classes.submitWrapper}>
        {
          success ? (
            <Typography variant="h6" color="inherit">
              Thanks for applying to join our network!
              <br />
              We are reviewing your information and will follow up with you in 1-2 business days!
            </Typography>
          ) : (
            <SubmitButton
              variant="contained"
              submitting={submitting}
              className={classes.submitButton}
              disabled={disableSubmit() || submitting}
              onClick={submit}
            >
              <Typography variant="subtitle2" color="inherit">
                Submit
              </Typography>
            </SubmitButton>
          )
        }
        {
          error ? (
            <Typography className={classes.error} variant="h6" color="inherit">
              Uh oh! Looks like something went wrong. Please try again.
            </Typography>
          ) : null
        }

        {getHTML(classes.disclaimer, classes.disclaimerLink)}
      </div>
    </div>
  );
};

AgentForm.propTypes = {
  classes: PropTypes.any,
};

AgentForm.defaultProps = {
  classes: {},
};

export default withStyles(styles)(AgentForm);
