const styleConfig = {
  media: {
    mobile: 760,
    mobileSmall: 450,
    mobileXSmall: 320,
  },
  size: {
    drawerWidth: 180,
  },
  duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    // most basic recommended timing
    standard: 300,
    // this is to be used in complex animations
    complex: 375,
    // recommended when something is entering screen
    enteringScreen: 225,
    // recommended when something is leaving screen
    leavingScreen: 195,
  },
};

module.exports = styleConfig;
