import React, { useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  styleConfig,
  useUserIPGrabber,
} from '@cleverrealestate/clever-helpers';
import { animateScroll } from 'react-scroll';
import withMUI from '../utils/MUI.utils';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import HappyAgentBg from '../images/img_comps/backgrounds/HappyAgentBg';
import AgentCheckMarks from '../components/agents/AgentCheckMarks';
import GetStartedButton from '../components/agents/GetStartedButton';
import TopAgents from '../components/global/TopAgents';
import ValuePropList from '../components/global/ValuePropList/ValuePropList';
import ValuePropIcon from '../components/global/ValuePropList/ValuePropIcon';
import CellPhoneIcon from '../images/img_comps/icons/CellPhoneIcon';
import ChartIcon from '../images/img_comps/icons/ChartIcon';
import ShieldIcon from '../images/img_comps/icons/ShieldIcon';
import ThumbsUpIcon from '../images/img_comps/icons/ThumbsUpIcon';
import HandShakeIcon from '../images/img_comps/icons/HandshakeIcon';
import SmartAgentsSection from '../components/agents/SmartAgentsSection';
import AgentReviews from '../components/agents/AgentReviews';
import CommissionSection from '../components/agents/CommissionSection';
import AgentForm from '../components/agents/AgentForm';
import AsSeenIn from '../components/global/AsSeenIn';
import { FAQDrawer, FAQPanel } from '../components/global/FAQDrawer';

const useStyles = makeStyles(theme => ({
  reviewPage: {
    ...theme.mixins.layout.column.base,
    position: 'relative',
    backgroundColor: 'white',
    alignItems: 'center',
    overflowX: 'hidden',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      ...theme.mixins.color.agentsBackgroundGradient,
    },
  },
  bgPhoto: {
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0px',
    '@media screen and (max-width: 1480px)': {
      width: '1480px',
      left: '50%',
      transform: 'translateX(-50%)',
      overflow: 'hidden',
    },
  },
  heroElementsWrapper: {
    ...theme.mixins.layout.row.base,
    ...theme.mixins.topBarAlign,
    height: 'calc(655/1920 * 100vw)',
    position: 'relative',
    paddingTop: '125px',
    '@media screen and (max-width: 1480px)': {
      height: 'calc(655/1920 * 1480px)',
    },
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      ...theme.mixins.topBarAlignMobile,
      ...theme.mixins.layout.column.base,
      marginBottom: '50px',
    },
  },
  heroLeft: {
    width: '50%',
    ...theme.mixins.layout.column.base,
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      width: '100%',
    },
  },
  heroRight: {
    ...theme.mixins.layout.column.base,
    width: '50%',
    height: '219px',
    justifyContent: 'center',
    alignItems: 'flex-end',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      width: '100%',
      height: '150px',
      alignItems: 'center',
    },
  },
  heroText: {
    ...theme.mixins.noSelect,
    color: 'white',
  },
  heroSubText: {
    fontSize: '0.9571428571428571rem',
    fontWeight: 300,
    lineHeight: 1.5,
    maxWidth: '480px',
    '@media screen and (max-width: 1170px)': {
      maxWidth: '325px',
    },
  },
  whiteBackground: {
    width: '100%',
    position: 'relative',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
  },
  grayBackground: {
    width: '100%',
    position: 'relative',
    backgroundColor: '#F7F7F7',
    display: 'flex',
    justifyContent: 'center',
  },
  sectionRow: {
    ...theme.mixins.topBarAlign,
    ...theme.mixins.layout.row.base,
    marginTop: '50px',
    marginBottom: '50px',
    position: 'relative',
    justifyContent: 'center',
  },
  topAlignSectionRow: {
    ...theme.mixins.topBarAlign,
    ...theme.mixins.layout.row.base,
    position: 'relative',
    justifyContent: 'center',
    marginBottom: '50px',
  },
  noAlignSectionRow: {
    ...theme.mixins.layout.column.base,
    marginTop: '50px',
    marginBottom: '50px',
    position: 'relative',
    width: '100%',
    overflowX: 'hidden',
  },
  getStartedWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: 'calc(100% - 50px)',
    margin: '50px 0 0 50px',
    height: '100px',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      marginLeft: '25px',
      marginRight: '25px',
    },
  },
}));

const AgentsHeroText = () => {
  const classes = useStyles();
  return (
    <div className={classes.heroText}>
      <Typography variant="h1" color="inherit" gutterBottom>
        Are you a top Agent?
      </Typography>
      <Typography
        className={classes.heroSubText}
        variant="h2"
        color="inherit"
        gutterBottom
      >
        Join over 7,000 top agents growing their business with Clever.
      </Typography>
    </div>
  );
};

const Agents = () => {
  useUserIPGrabber();
  const classes = useStyles();
  const formRef = useRef(null);

  const scrollToForm = () => animateScroll.scrollTo(formRef.current.offsetTop - 100);
  const desktop = useMediaQuery(`(min-width: ${styleConfig.media.mobile + 1}px)`);
  return (
    <Layout
      colorTransitionStart={25}
      shadowTransitionStart={25}
      colorTransitionLength={100}
      shadowTransitionLength={100}
    >
      <SEO
        pathname="/top-real-estate-agents-needed/"
        title="Get More Real Estate Leads | Join the Clever Referral Network"
        description={'Want to get more real estate leads and referrals?'
          + ' Join the Clever Partner Network to get qualified leads and grow your book of business.'}
        addWebsiteName={false}
      />
      <div className={classes.reviewPage}>
        {desktop && (
          <>
            <div className={classes.bgPhoto}>
              <HappyAgentBg />
            </div>
          </>
        )}
        <div className={classes.heroElementsWrapper}>
          <div className={classes.heroLeft}>
            <AgentsHeroText classes={classes} />
            <AgentCheckMarks />
          </div>
          <div className={classes.heroRight}>
            <GetStartedButton onClick={scrollToForm} />
          </div>
        </div>

        <div className={classes.whiteBackground}>
          <div className={classes.sectionRow}>
            <TopAgents title="Who Can Become a Clever Partner Agent?">
              <Typography variant="body1" color="inherit">
                Clever is looking for top-rated agents that want to grow their business.
                Becoming a Clever partner agent means committing to the success of referrals we send you.
              </Typography>
            </TopAgents>
          </div>
        </div>

        <div className={classes.grayBackground}>
          <div className={classes.sectionRow}>
            <ValuePropList title="Clever looks for...">
              <ValuePropIcon
                text="Professional full time agents with over 5 years of experience."
                icon={<ShieldIcon />}
                textColor="gray"
              />
              <ValuePropIcon
                text="Great reviews from past sellers."
                icon={<ThumbsUpIcon />}
                textColor="gray"
              />
              <ValuePropIcon
                text="Extensive local market knowledge."
                icon={<ChartIcon />}
                textColor="gray"
              />
              <ValuePropIcon
                text="Great negotiators to help their clients win."
                icon={<HandShakeIcon />}
                textColor="gray"
              />
              <ValuePropIcon
                text="Agents who love technology that makes them more efficient."
                icon={<CellPhoneIcon />}
                textColor="gray"
              />
            </ValuePropList>
          </div>
        </div>

        <div className={classes.whiteBackground}>
          <div className={classes.sectionRow}>
            <AsSeenIn />
          </div>
        </div>

        <div className={classes.grayBackground}>
          <div className={classes.noAlignSectionRow}>
            <SmartAgentsSection />
            <div className={classes.getStartedWrapper}>
              <GetStartedButton textColor="gray" onClick={scrollToForm} />
            </div>
          </div>
        </div>

        <div className={classes.whiteBackground}>
          <div className={classes.topAlignSectionRow}>
            <FAQDrawer title="Frequently Asked Questions">
              <FAQPanel
                question="Does Clever cost me anything to sign up?"
                answer={'No. Clever is totally free for agents.'
                  + ' We get paid as a referral fee from any homes you help buy or sell.'}
              />
              <FAQPanel
                question="Can I pay to receive more leads than other agents?"
                answer={'No, our goal is to send the best possible agents to our buyers and sellers.'
                  + ' The best way to get more leads is to get great reviews,'
                  + ' and go above and beyond for the business we send you.'}
              />
              <FAQPanel
                question="How does Clever qualify homeowners?"
                answer={'We find out all of the important information before we send you any clients,'
                  + ' so you will have everything you need to provide fantastic service.'}
              />
              <FAQPanel
                question="How involved is Clever after we send you a Client?"
                answer={'Once we send you a client, treat them with the same care and level of service'
                  + ' that you would provide for anyone else that uses your services.'
                  + ' However, if you have any questions, we’re always here to help!'}
              />
            </FAQDrawer>
          </div>
        </div>

        <div className={classes.grayBackground}>
          <div className={classes.noAlignSectionRow}>
            <AgentReviews />
          </div>
        </div>

        <div ref={formRef} className={classes.whiteBackground}>
          <div className={classes.topAlignSectionRow}>
            <CommissionSection />
          </div>
        </div>

        <div className={classes.whiteBackground}>
          <div className={classes.topAlignSectionRow}>
            <AgentForm />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withMUI(Agents);
