import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const SmallHouseIcon = () => (
  <StaticQuery
    query={graphql`
      query {
        smallHouseIcon: file(relativePath: { eq: "icons/SmallHouseIcon.png" }) {
          childImageSharp {
            gatsbyImageData(height: 120)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.smallHouseIcon.childImageSharp.gatsbyImageData} alt="Small House Icon" />}
  />
);

export default SmallHouseIcon;
