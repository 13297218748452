import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import TrustPilot5Stars from '../../images/img_comps/trustPilot/TrustPilot5Stars';
import styleConfig from '../../style-config';

const styles = theme => ({
  reviewWrapper: {
    width: '315px',
    height: '220px',
    margin: '0 25px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media screen and (max-width: ${styleConfig.media.mobileSmall}px)`]: {
      margin: '0px',
    },
  },
  background: {
    position: 'relative',
    width: '285px',
    height: '190px',
    backgroundColor: 'white',
    boxShadow: theme.shadows[3],
    borderRadius: '8px',
  },
  photoBackground: {
    width: '80px',
    height: '80px',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '-15px',
    top: '-15px',
    borderRadius: '40px',
    ...theme.mixins.color.reviewCircleGradient,
  },
  calloutBackground: {
    width: '160px',
    height: '38px',
    position: 'absolute',
    right: '-15px',
    bottom: '15px',
    backgroundColor: '#00B67A',
    boxShadow: theme.shadows[3],
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  reviewerInfoWrapper: {
    color: '#1e1e1e',
    position: 'absolute',
    left: '15px',
    bottom: '15px',
  },
  reviewText: {
    marginTop: '8px',
    marginRight: '6px',
    height: '120px',
    overflowY: 'hidden',
    textOverflow: 'elipsis',
    display: 'inline-block',
    position: 'relative',
    lineHeight: '1.49',
    '&:after': {
      content: '""',
      textAlign: 'right',
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '100px',
      height: '20px',
      background: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%)',
    },
  },
  noPicture: {
    marginLeft: '15px',
  },
  floater1: {
    display: 'block',
    float: 'left',
    clear: 'left',
    width: '72px',
    height: '15px',
  },
  floater2: {
    display: 'block',
    float: 'left',
    clear: 'left',
    width: '72px',
    height: '15px',
  },
  floater3: {
    display: 'block',
    float: 'left',
    clear: 'left',
    width: '62px',
    height: '15px',
  },
  floater4: {
    display: 'block',
    float: 'left',
    clear: 'left',
    width: '10px',
    height: '70px',
  },
});

const Review = ({
  classes, name, text, callout, picture,
}) => (
  <div className={classes.reviewWrapper}>
    <div className={classes.background}>
      { picture && (
        <div className={classes.photoBackground}>
          { picture }
        </div>
      )}
      <Typography
        className={classNames(classes.reviewText, { [classes.noPicture]: !picture })}
        variant="body1"
        color="inherit"
      >
        { picture && (
          <>
            <span className={classes.floater1} />
            <span className={classes.floater2} />
            <span className={classes.floater3} />
            <span className={classes.floater4} />
          </>
        )}
        { text }
      </Typography>
      <div className={classes.calloutBackground}>
        <Typography variant="h6" color="inherit">
          { callout }
        </Typography>
      </div>
      <div className={classes.reviewerInfoWrapper}>
        <Typography variant="subtitle1" color="inherit">
          <b>{ name }</b>
        </Typography>
        <TrustPilot5Stars />
      </div>
    </div>
  </div>
);

Review.propTypes = {
  classes: PropTypes.any,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  callout: PropTypes.string.isRequired,
  picture: PropTypes.node,
};

Review.defaultProps = {
  classes: {},
  picture: null,
};

export default withStyles(styles)(Review);
