import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  styleConfig,
} from '@cleverrealestate/clever-helpers';
import FriendlyAgents from '../../images/img_comps/people/FriendlyAgents';
import FriendlyAgentsMobile from '../../images/img_comps/people/FriendlyAgentsMobile';

const useStyles = makeStyles(theme => ({
  smartAgentWrapper: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 'calc((100vw/2) - (1194.63px/2))',
    '@media screen and (max-width: 1295px)': {
      marginLeft: '50px',
      width: 'calc(100% - 50px)',
    },
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      flexDirection: 'column',
      marginLeft: '25px',
      marginRight: '25px',
      width: 'calc(100% - 50px)',
      alignItems: 'center',
    },
  },
  title: {
    color: theme.mixins.color.titleTextBlue,
  },
  body: {
    marginTop: '25px',
  },
  textWrapper: {
    marginRight: '0px',
    height: '345px',
    width: '533px',
    '@media screen and (max-width: 996px)': {
      paddingRight: '25px',
      display: 'flex',
      flexDirection: 'column',
      width: '57%',
      height: 'auto',
    },
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      width: '100%',
      paddingRight: '0',
    },
  },
  photoContainer: {
    height: '345px',
    overflowY: 'hidden',
    position: 'absolute',
    left: '560px',
    '@media screen and (max-width: 996px)': {
      left: 'inherit',
      position: 'relative',
    },
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      marginTop: '25px',
      position: 'relative',
    },
  },
  mobilePhotoWrapper: {
    display: 'none',
    height: '345px',
    '@media screen and (max-width: 996px)': {
      display: 'flex',
      width: '100%',
    },
  },
}));

const SmartAgentsSection = () => {
  const classes = useStyles();
  const desktop = useMediaQuery('(max-width: 996px)');
  return (
    <div className={classes.smartAgentWrapper}>
      <div className={classes.textWrapper}>
        <Typography className={classes.title} variant="h6" color="inherit">
          Smart Agents Work With Clever to Grow Their Business
        </Typography>
        <Typography className={classes.body} variant="body1" color="inherit">
          Agents spend thousands of dollars on marketing to grow their business.
          Great agents do this because they know that once they serve a new client well,
          they have a client for life. Clever real estate is a great agent’s best friend.
          Instead of spending thousands up front on marketing,
          you discount your commission in exchange for new business.
          <br />
          <br />
          Clever Partner Agents are incredible marketers, and the visibility they receive from
          working our listings generates a constant stream of new business.
          <br />
          <br />
          As long as you keep getting great reviews from the buyers and sellers we send you,
          we will keep sending you more business,
          increasing your brand awareness in your local  market. Now that’s a win win!
        </Typography>
      </div>
      <div className={classes.photoContainer}>
        { desktop && (
          <div className={classes.mobilePhotoWrapper}>
            <FriendlyAgentsMobile />
          </div>
        )}
        {!desktop && (
          <>
            <FriendlyAgents />
            <div className={classes.mobilePhotoWrapper}>
              <FriendlyAgentsMobile />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

SmartAgentsSection.propTypes = {};

SmartAgentsSection.defaultProps = {};

export default SmartAgentsSection;
