import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const TrustPilotBlack = () => (
  <StaticQuery
    query={graphql`
      query {
        trustPilot: file(relativePath: { eq: "trustPilot/TrustPilotBannerBlack.png" }) {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.trustPilot.childImageSharp.gatsbyImageData} alt="Trust Pilot" />}
  />
);

export default TrustPilotBlack;
