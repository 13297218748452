import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';


const styles = theme => ({
  valueIconWrapper: {
    ...theme.mixins.layout.column.base,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'start',
    margin: '25px auto',
    padding: '0 10px',
  },
  narrow: {
    width: '195px',
  },
  wide: {
    width: '320px',
  },
  iconOnly: {
    width: '180px',
    height: '75px',
    padding: '0',
  },
  title: {
    color: theme.mixins.color.titleTextBlue,
  },
  body: {
    marginTop: '5px',
  },
  white: {
    color: 'white',
  },
  gray: {
    marginTop: '25px',
    color: theme.mixins.color.grayText,
  },
  iconWrapper: {
    height: '75px',
    display: 'flex',
    alignItems: 'center',
  },
  textWrapper: {
    maxWidth: '100%',
  },
});

const ValuePropIcon = ({
  classes, icon, title, text, textColor, wide,
}) => {
  const isWhite = textColor === 'white';

  return (
    <div
      className={
        classNames(
          classes.valueIconWrapper,
          { [classes.iconOnly]: !text },
          { [classes.narrow]: !wide },
          { [classes.wide]: wide },
        )
      }
    >
      <div className={classes.iconWrapper}>
        { icon }
      </div>
      {
        text ? (
          <div className={classNames(classes.textWrapper, { [classes.white]: isWhite, [classes.gray]: !isWhite })}>
            {
              title ? (
                <Typography className={classes.title} variant="body1" color="inherit">
                  <b>{ title }</b>
                </Typography>
              ) : null
            }
            <Typography className={classes.body} variant="body1" color="inherit">
              { text }
            </Typography>
          </div>
        ) : null
      }
    </div>
  );
};

ValuePropIcon.propTypes = {
  classes: PropTypes.any,
  icon: PropTypes.node.isRequired,
  wide: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.oneOf(['white', 'gray']),
};

ValuePropIcon.defaultProps = {
  classes: {},
  textColor: 'white',
  title: null,
  text: null,
  wide: false,
};

export default withStyles(styles)(ValuePropIcon);
