import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  propListWrapper: {
    ...theme.mixins.layout.column.base,
    ...theme.mixins.noSelect,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  title: {
    color: theme.mixins.color.titleTextBlue,
    marginBottom: '25px',
  },
  iconsWrapper: {
    ...theme.mixins.layout.row.base,
    alignItems: 'end',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
});

const ValuePropList = ({ classes, title, children }) => (
  <div className={classes.propListWrapper}>
    {
      title ? (
        <Typography className={classes.title} variant="h6" color="inherit">
          { title }
        </Typography>
      ) : null
    }
    <div className={classes.iconsWrapper}>
      { children }
    </div>
  </div>
);


ValuePropList.propTypes = {
  classes: PropTypes.any,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ValuePropList.defaultProps = {
  classes: {},
  title: null,
};

export default withStyles(styles)(ValuePropList);
