import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import BigHouseIcon from '../../images/img_comps/icons/BigHouseIcon';
import SmallHouseIcon from '../../images/img_comps/icons/SmallHouseIcon';

const styles = theme => ({
  commissionWrapper: {
    ...theme.mixins.layout.column.base,
    width: '100%',
  },
  titleWrapper: {
    ...theme.mixins.layout.column.base,
    ...theme.mixins.noSelect,
    alignItems: 'center',
    color: theme.mixins.color.titleTextBlue,
    margin: '50px 0 25px 0',
    textAlign: 'center',
  },
  title: {
    maxWidth: '100%',
  },
  body: {
    color: theme.mixins.color.grayText,
    textAlign: 'center',
    maxWidth: '100%',
  },
  iconsWrapper: {
    ...theme.mixins.layout.row.base,
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '0 50px 25px',
    borderBottomWidth: '1px',
    borderBottomColor: '#6060605e',
    borderBottomStyle: 'solid',
    '@media screen and (max-width: 901px)': {
      ...theme.mixins.layout.column.base,
    },
  },
  houseText: {
    ...theme.mixins.layout.column.base,
    ...theme.mixins.noSelect,
    color: theme.mixins.color.grayText,
    marginLeft: '25px',
    '@media screen and (max-width: 901px)': {
      marginLeft: '0px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  houseTitle: {
    ...theme.mixins.layout.row.base,
    '@media screen and (max-width: 901px)': {
      textAlign: 'center',
    },
  },
  blue: {
    color: theme.mixins.color.titleTextBlue,
  },
  rightHouse: {
    marginLeft: '75px',
    '@media screen and (max-width: 901px)': {
      marginLeft: '0px',
      marginTop: '25px',
    },
  },
  houseIcon: {
    ...theme.mixins.layout.row.base,
    alignItems: 'center',
    justifyContent: 'center',
    '@media screen and (max-width: 901px)': {
      ...theme.mixins.layout.column.base,
    },
  },
});

const CommissionSection = ({ classes }) => (
  <div className={classes.commissionWrapper}>
    <div className={classes.titleWrapper}>
      <Typography className={classes.title} variant="h6" color="inherit">
        How Does Clever&#39;s Commission Structure Work?
      </Typography>
      <Typography className={classes.body} variant="body1" color="inherit">
        See how Clever helps homeowners save on their listing commission
      </Typography>
    </div>
    <div className={classes.iconsWrapper}>
      <div className={classes.houseIcon}>
        <SmallHouseIcon />
        <div className={classes.houseText}>
          <div className={classes.houseTitle}>
            <Typography variant="h6" color="inherit">
              <span className={classes.blue}>$3000&nbsp;</span>
              Flat Listing Fee
            </Typography>
          </div>
          <Typography className={classes.body} variant="body1" color="inherit">
            Homes Less Than $350,000
          </Typography>
        </div>
      </div>

      <div className={classNames(classes.houseIcon, classes.rightHouse)}>
        <BigHouseIcon />
        <div className={classes.houseText}>
          <div className={classes.houseTitle}>
            <Typography variant="h6" color="inherit">
              <span className={classes.blue}>1%&nbsp;</span>
              Listing Fee
            </Typography>
          </div>
          <Typography className={classes.body} variant="body1" color="inherit">
            Homes More Than $350,000
          </Typography>
        </div>
      </div>
    </div>
  </div>
);


CommissionSection.propTypes = {
  classes: PropTypes.any,
};

CommissionSection.defaultProps = {
  classes: {},
};

export default withStyles(styles)(CommissionSection);
