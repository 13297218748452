import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const FriendlyAgents = () => (
  <StaticQuery
    query={graphql`
      query {
        friendlyAgents: file(relativePath: { eq: "people/FriendlyAgentHeadshots.png" }) {
          childImageSharp {
            gatsbyImageData(height: 345, quality: 90)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.friendlyAgents.childImageSharp.gatsbyImageData} alt="Friendly Agents" />}
  />
);
export default FriendlyAgents;
