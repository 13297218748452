import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const JCYFace = () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "people/agentFaces/JCY.png" }) {
          childImageSharp {
            gatsbyImageData(width: 72)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.image.childImageSharp.gatsbyImageData} alt="JCY's Face" />}
  />
);

export default JCYFace;
