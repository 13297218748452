import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const HappyAgentBg = () => (
  <StaticQuery
    query={graphql`
      query {
        happyAgentBg: file(relativePath: { eq: "background/HappyAgentBg.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, height: 655, quality: 90)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.happyAgentBg.childImageSharp.gatsbyImageData} alt="Happy Agent" />}
  />
);
export default HappyAgentBg;
