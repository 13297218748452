import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const ThumbsUpIcon = () => (
  <StaticQuery
    query={graphql`
      query {
        thumbsUpIcon: file(relativePath: { eq: "icons/ThumbsUpIcon.png" }) {
          childImageSharp {
            gatsbyImageData(height: 75)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.thumbsUpIcon.childImageSharp.gatsbyImageData} alt="Thumbs Up Icon" />}
  />
);

export default ThumbsUpIcon;
