import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// eslint-disable-next-line no-unused-vars
const styles = theme => ({});

const FAQPanel = ({ question, answer }) => (
  <ExpansionPanel>
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography variant="h6" color="inherit">
        {question}
      </Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      <Typography variant="body1" color="inherit" dangerouslySetInnerHTML={{ __html: answer }} />
    </ExpansionPanelDetails>
  </ExpansionPanel>
);


FAQPanel.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
};

FAQPanel.defaultProps = {
  question: '',
  answer: '',
};

export default withStyles(styles)(FAQPanel);
