import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';


const styles = theme => ({
  getStartedWrapper: {
    ...theme.mixins.layout.column.base,
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '100%',
    // width: '100%',
  },
  white: {
    color: 'white',
  },
  gray: {
    color: theme.mixins.color.grayText,
  },
  getStartedText: {
    maxWidth: '100%',
  },
  getStartedButton: {
    borderRadius: '4px',
    marginTop: '15px',
    width: '130px',
    height: '40px',
    color: 'white',
    ...theme.mixins.color.buttonGradient,
  },
});

const GetStartedButton = ({ classes, textColor, onClick }) => {
  const isWhite = textColor === 'white';

  return (
    <div className={classNames(classes.getStartedWrapper, { [classes.white]: isWhite, [classes.gray]: !isWhite })}>
      <Typography variant="body2" color="inherit" className={classes.getStartedText}>
        Ready to see if you qualify to join Clever&#39;s Partner Network?
      </Typography>
      <Button onClick={onClick} variant="contained" className={classes.getStartedButton}>
        <Typography variant="subtitle2" color="inherit">
          Get Started
        </Typography>
      </Button>
    </div>
  );
};

GetStartedButton.propTypes = {
  classes: PropTypes.any,
  textColor: PropTypes.oneOf(['white', 'gray']),
  onClick: PropTypes.func.isRequired,
};

GetStartedButton.defaultProps = {
  classes: {},
  textColor: 'white',
};

export default withStyles(styles)(GetStartedButton);
