import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import AgentStats from '../../images/img_comps/people/AgentStats';
import styleConfig from '../../style-config';


const styles = theme => ({
  topAgentsWrapper: {
    ...theme.mixins.layout.row.base,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  imageWrapper: {
    maxWidth: '350px',
    width: '100%',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      marginBottom: '25px',
    },
  },
  textWrapper: {
    marginLeft: '50px',
    maxWidth: '100%',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      marginLeft: '0',
    },
  },
  title: {
    color: theme.mixins.color.titleTextBlue,
  },
  childTextWrapper: {
    maxWidth: '550px',
    color: theme.mixins.color.grayText,
  },
});

const TopAgents = ({ classes, title, children }) => (
  <div className={classes.topAgentsWrapper}>
    <div className={classes.imageWrapper}>
      <AgentStats />
    </div>
    <div className={classes.textWrapper}>
      <Typography className={classes.title} variant="h6" color="inherit">
        { title }
      </Typography>
      <div className={classes.childTextWrapper}>
        { children }
      </div>
    </div>
  </div>
);

TopAgents.propTypes = {
  classes: PropTypes.any,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

TopAgents.defaultProps = {
  classes: {},
};

export default withStyles(styles)(TopAgents);
